<template>
  <div>
    <div v-if="matrix2_view">
      <b-button
        variant="primary"
        size="sm"
        class="mb-2 ml-3 noprint"
        @click="$bvModal.show('remove-egress-profile-matters-modal')"
        >Eliminar
        {{
          $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas")
        }}</b-button
      >
      <b-modal
        id="remove-egress-profile-matters-modal"
        :title="`Eliminar ${$getVisibleNames(
          'mesh.egressprofilematter',
          true,
          'Asignaturas'
        )}`"
        size="xl"
        @hide="hideModals"
        hide-footer
      >
        <GenericBTable
          :items="matterList"
          :fields="fields"
          :filter="input_search"
          :filter-included-fields="filterOn"
          :filterCustom="filterCustom"
          :show_pagination="true"
          :search_filter="true"
          :selection_mode="true"
          :columns_display="false"
          :pagination="10"
          :per_page="10"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @emitChangeSearchField="changeInputSearch"
        >
          <template #head(selected)>
            <b-form-checkbox
              v-model="select_all"
              @change="selectAllEPMatter()"
            ></b-form-checkbox>
          </template>
          <template #cell(selected)="row">
            <b-checkbox
              v-model="row.item.selected"
              @change="selectEPMatter(row.item.id, $event)"
            ></b-checkbox>
          </template>
        </GenericBTable>
        <h4 v-if="selected.length > 0">
          {{
            $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas")
          }}
          Seleccionadas
        </h4>
        <div class="matters-container">
          <div
            v-for="item in matterList.filter((x) => selected.includes(x.id))"
            :key="item.id"
            class="matters-div"
          >
            {{ (matters.find((x) => x.id == item.matter) || {}).name || "" }}
          </div>
        </div>
        <div class="row">
          <div class="col" style="text-align: right">
            <template v-if="selected.length > 0">
              <b-button class="mr-1" size="sm" @click="removeMatters()">
                Remover
                {{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    true,
                    "Asignaturas"
                  )
                }}
              </b-button>
            </template>
            <template v-else>
              <b-button class="mr-1" size="sm" disabled
                >Remover
                {{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    true,
                    "Asignaturas"
                  )
                }}</b-button
              >
            </template>
          </div>
        </div>
      </b-modal>
    </div>
    <div v-else>
      <div class="d-flex">
        <h3 class="ml-4">
          {{
            $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas")
          }}
        </h3>
        <div class="ml-3" v-if="!copy_egress_profile_view">
          <template v-if="selected.length > 0">
            <b-button class="mr-1" size="sm" @click="TemporalChangedMatters()"
              >Reasignar el nivel "{{ semester_amount }}" a
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  true,
                  "Asignaturas"
                )
              }}
              Seleccionadas</b-button
            >
          </template>
          <template v-else>
            <b-button class="mr-1" size="sm" disabled
              >Reasignar el nivel "{{ semester_amount }}" a
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  true,
                  "Asignaturas"
                )
              }}
              Seleccionadas</b-button
            >
          </template>
        </div>
      </div>
      <GenericBTable
        :items="matterList"
        :fields="fields"
        :filter="input_search"
        :filter-included-fields="filterOn"
        :filterCustom="filterCustom"
        :show_pagination="true"
        :search_filter="true"
        :selection_mode="true"
        :columns_display="false"
        :pagination="10"
        :per_page="10"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @emitChangeSearchField="changeInputSearch"
      >
        <template #head(selected)>
          <b-form-checkbox
            v-model="select_all"
            @change="selectAllEPMatter()"
          ></b-form-checkbox>
        </template>
        <template #cell(selected)="row">
          <b-checkbox
            v-model="row.item.selected"
            @change="selectEPMatter(row.item.id, $event)"
          ></b-checkbox>
        </template>
        <template #cell(plan_level)="row">
          <b-form-input
            style="width: auto"
            type="number"
            min="1"
            v-model="row.item.plan_level"
            @update="updateInput()"
            :state="
              row.item.plan_level <= semester_amount && row.item.plan_level >= 1
            "
            v-b-tooltip.v-danger.noninteractive="
              `${
                row.item.plan_level <= semester_amount &&
                row.item.plan_level >= 1
                  ? ''
                  : 'El nivel del plan de la ' +
                    $getVisibleNames(
                      'mesh.egressprofilematter',
                      false,
                      'Asignatura'
                    ) +
                    ' sobrepasa al del ' +
                    $getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    )
              }`
            "
            size="sm"
          ></b-form-input>
        </template>
        <!-- <template #cell(actions)="row">
          <button-edit
            v-if="!matrix2_view"
            @click="
              $bvModal.show(`edit-egress-profile-matter-modal-${row.item.id}`)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.egressprofilematter',
                false,
                'Asignatura'
              )}`
            "
          ></button-edit>
          <b-modal
            :id="`edit-egress-profile-matter-modal-${row.item.id}`"
            hide-footer
            :title="`Editar ${$getVisibleNames(
              'mesh.egressprofilematter',
              false,
              'Asignatura'
            )}`"
            size="lg"
          >
            <MatterForm
              :Matter="row.item"
              :egress_profile_id="row.item.egress_profile"
              :show_delete_button="true"
              @update="
                $bvModal.hide(`edit-egress-profile-matter-modal-${row.item.id}`)
              "
              @updated="
                $bvModal.hide(`edit-egress-profile-matter-modal-${row.item.id}`)
              "
            ></MatterForm>
          </b-modal>
        </template> -->
      </GenericBTable>
      <div class="d-block">
        <template v-if="selected.length > 0">
          <div class="d-block ml-3">
            <h4>
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  true,
                  "Asignaturas"
                )
              }}
              selecionadas para no ser copiadas:
            </h4>
            <div class="mb-3">
              <template v-if="selected.length > 0">
                <b-button
                  class="mr-1"
                  size="sm"
                  @click="TemporalRemoveMatters()"
                  >Remover
                  {{
                    $getVisibleNames(
                      "mesh.egressprofilematter",
                      true,
                      "Asignaturas"
                    )
                  }}
                  Seleccionadas
                  <template v-if="copy_egress_profile_view">
                    de la copia
                  </template>
                </b-button>
              </template>
              <template v-else>
                <b-button class="mr-1" size="sm" disabled
                  >Remover
                  {{
                    $getVisibleNames(
                      "mesh.egressprofilematter",
                      true,
                      "Asignaturas"
                    )
                  }}
                  Seleccionadas
                  <template v-if="copy_egress_profile_view">
                    de la copia
                  </template></b-button
                >
              </template>
            </div>
            <div class="matters-container mb-4">
              <div
                v-for="item in matterList.filter((x) =>
                  selected.includes(x.id)
                )"
                :key="item.id"
                class="matters-div"
              >
                {{
                  (matters.find((x) => x.id == item.matter) || {}).name || ""
                }}
              </div>
            </div>
          </div>
        </template>
        <template v-if="remove_matters.length > 0">
          <div class="d-block ml-2">
            <h5>
              <template v-if="copy_egress_profile_view">Listado de </template>
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  true,
                  "Asignaturas"
                )
              }}
              <template v-if="!copy_egress_profile_view"> a eliminar </template>
              <template v-else> que no se copiaran:</template>
            </h5>
            <div class="matters-container">
              <div
                v-for="item in remove_matters"
                :key="item.id"
                class="matters-div deleted-matter"
              >
                {{
                  (matters.find((x) => x.id == item.matter) || {}).name || ""
                }}
                <b-button
                  variant="none"
                  size="sm"
                  class="m-0 p-0"
                  @click="reintegrateMatters(item)"
                >
                  <b-icon
                    icon="x-square"
                    scale="0.9"
                    variant="white"
                    v-b-tooltip.v-secondary.noninteractive="
                      `Integrar ${$getVisibleNames(
                        'mesh.egressprofilematter',
                        false,
                        'Asignatura'
                      )}`
                    "
                  ></b-icon>
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { generateUniqueId } from "@/utils/utils";
export default {
  name: "RemoveEPMattersComponent",
  components: {
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    // MatterForm: () => import("./MatterForm"),
  },
  props: {
    allows_crud: {
      type: Boolean,
    },
    matrix2_view: {
      type: Boolean,
      default: true,
    },
    copy_egress_profile_view: {
      type: Boolean,
      default: false,
    },
    egress_profile_id: {
      type: Number,
      required: true,
    },
    semester_amount: {
      default: 1,
    },
  },
  data() {
    return {
      select_all: false,
      selected: [],
      filterOn: [],
      list_matters: [],
      sortBy: "",
      sortDesc: "",
      input_search: "",
      remove_matters: [],
    };
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
    }),
    matterList() {
      if (this.matrix2_view) {
        let list = [
          ...this.egressProfileMatters.filter(
            (x) => x.egress_profile == this.egress_profile_id
          ),
        ];
        return list.sort(function (a, b) {
          if (a.plan_level > b.plan_level) return 1;
          if (a.plan_level < b.plan_level) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
      } else {
        let list = this.list_matters;
        return list.sort(function (a, b) {
          if (a.plan_level > b.plan_level) return 1;
          if (a.plan_level < b.plan_level) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
      }
    },
    fields() {
      let list = [
        {
          key: "selected",
          label: "Seleccionar",
          display_column: true,
          thStyle: { width: "1%" },
        },
        {
          key: "plan_level",
          label: "Nivel del plan",
          display_column: true,
          tdClass: "text-center",
          tdStyle: { width: "auto" },
          thStyle: {
            background: "var(--primary-color) !important",
            width: "10%",
          },
        },
        {
          key: "id",
          label: this.$getVisibleNames("manual.matter_code", false, "Código"),
          formatter: (value) => {
            const matter_id =
              (this.egressProfileMatters.find((x) => x.id == value) || {})
                .matter || null;
            return (
              (this.matters.find((x) => x.id == matter_id) || {}).code || ""
            );
          },
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "matter",
          label: this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          ),
          formatter: (value) => {
            return (this.matters.find((x) => x.id == value) || {}).name || "";
          },
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        // {
        //   key: "actions",
        //   label: "",
        //   display_column: !this.matrix2_view ? true : false,
        //   thStyle: { background: "var(--primary-color) !important" },
        //   thClass: "noprint",
        //   tdClass: "noprint",
        // },
      ];
      return list;
    },
  },
  methods: {
    removeMatters() {
      this.$swal({
        title: `¿Desea remover las ${this.$getVisibleNames(
          "mesh.egressprofilematter",
          true,
          "Asignaturas"
        )} seleccionadas?`,
        width: "auto",
        text: `Esta acción no se podrá revertir y se desvinculará los objetos seleccionados de este ${this.$getVisibleNames(
          "mesh.egressprofile",
          false,
          "Perfil de Egreso"
        )}. `,
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          //   footer: `
          //   <div class="block">
          //   <h3 v-if="${this.selected.length > 0}">
          //   ${this.$getVisibleNames(
          //     "mesh.egressprofilematter",
          //     true,
          //     "Asignaturas"
          //   )}
          //   Seleccionadas
          // </h3>
          //   <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
          //   ${this.matterList
          //     .filter((x) => this.selected.includes(x.id))
          //     .map(
          //       (
          //         render
          //       ) => `<div style="background-color: rgb(153,201,216);color: white;max-width: fit-content;border-radius: 3px;margin: 0.1em 0.1em;padding: 1px 4px;flex-grow: 1;font-size: 9pt;text-align: center;" :key="${
          //         render.id
          //       }"> ${
          //         (this.matters.find((x) => x.id == render.matter) || {}).name || ""
          //       }</div>
          //     `
          //     )
          //     .join("")}</div>
          // `,
          this.removeMatterAndCourses(this.selected);
          this.$bvModal.hide("remove-egress-profile-matters-modal");
        }
      });
    },
    async removeMatterAndCourses(egress_profile_filter) {
      for await (let ep_matter_id of egress_profile_filter) {
        await this.$store.dispatch(
          names.DELETE_EGRESS_PROFILE_MATTER,
          ep_matter_id
        );
      }
      this.selected = [];
      this.select_all = false;
    },
    updateInput() {
      this.$emit("list_matters", this.list_matters, this.remove_matters);
    },
    TemporalRemoveMatters() {
      this.list_matters
        .filter((x) => this.selected.includes(x.id))
        .forEach((x) => {
          x.selected = false;
          this.remove_matters.push(x);
          const index = this.list_matters.findIndex((a) => a.id == x.id);
          if (index != -1) this.list_matters.splice(index, 1);
        });
      this.selected = [];
      this.select_all = false;
      this.$emit("list_matters", this.list_matters, this.remove_matters);
    },
    TemporalChangedMatters() {
      this.list_matters
        .filter((x) => this.selected.includes(x.id))
        .forEach((x) => {
          x.plan_level = this.semester_amount;
          x.selected = false;
        });
      this.selected = [];
      this.select_all = false;
      this.$emit("list_matters", this.list_matters, this.remove_matters);
    },
    reintegrateMatters(matter) {
      const index = this.remove_matters.findIndex((x) => x.id == matter.id);
      if (index != -1) {
        this.remove_matters.splice(index, 1);
        this.list_matters.push(matter);
      }
      this.$emit("list_matters", this.list_matters, this.remove_matters);
      this.select_all = false;
    },
    hideModals() {
      this.select_all = false;
      this.selected = [];
      this.matterList.forEach((x) => {
        x.selected = false;
      });
    },
    filterCustom(row) {
      if (
        this.$filtered(
          (this.matters.find((x) => x.id == row.matter) || {}).name || "",
          this.input_search
        ) ||
        this.$filtered(
          (this.matters.find((x) => x.id == row.matter) || {}).code || "",
          this.input_search
        ) ||
        this.$filtered(row.plan_level, this.input_search)
      ) {
        return true;
      } else {
        return false;
      }
    },
    selectEPMatter(matter_id) {
      const index = this.selected.findIndex((x) => x == matter_id);
      if (index != -1) this.selected.splice(index, 1);
      else this.selected.push(matter_id);
    },
    selectAllEPMatter() {
      let list = [];
      if (!this.select_all) {
        this.matterList.forEach((x) => {
          x.selected = false;
        });
        this.selected = [];
      } else {
        this.matterList.forEach((x) => {
          list.push(x.id);
          x.selected = true;
        });
        this.selected = [...new Set(list)];
      }
    },
    changeInputSearch(input_search) {
      if (input_search) this.input_search = input_search;
    },
  },
  watch: {
    selected() {
      if (
        this.selected.length == this.matterList.length &&
        this.matterList.length > 0
      ) {
        this.select_all = true;
      } else if (
        this.selected.length != this.matterList.length &&
        this.matterList.length > 0
      )
        this.select_all = false;
    },
  },
  mounted() {
    this.select_all = false;
    this.selected = [];
    this.list_matters = [];
    if (!this.matrix2_view) {
      if (!this.copy_egress_profile_view) {
        this.list_matters = this.egressProfileMatters
          .filter(
            (x) =>
              x.egress_profile == this.egress_profile_id &&
              x.plan_level > this.semester_amount
          )
          .map((objeto) => ({ ...objeto }));
      } else {
        this.list_matters = this.egressProfileMatters
          .filter((x) => x.egress_profile == this.egress_profile_id)
          .map((objeto) => ({ ...objeto }));
      }
      this.$emit("list_matters", this.list_matters, this.remove_matters);
    }
  },
  created() {},
};
</script>

<style scoped>
.matters-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.deleted-matter {
  background-color: red !important;
}
.matters-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>